
'use strict';

import { resolve } from "upath";

function print(value) {
    return `Spin360n: ${value}`;
}

class Spin360n {
    constructor(node, options={}) {

        if (node instanceof HTMLElement) {
            this.node = node;
        } else if (typeof node === 'string') {
            this.node = document.querySelector(node);
        }

        if (!this.node){
            throw Error(print('Node not found'));
        }

        this.options = Object.assign({
            imgClass: 'spin-360__image',
            width: '100%',
            height: 'auto',
            type: 'images',
            lazyLoad: true,
            reverse: false,
            listener: this.node,
            controls: null,
            onInit: function(){},
        }, options);

        this.listener = this.options.listener;
        this.activeIndex = 0;
        this.selector = 'spin-360'
        this.collection = null;
        this.collection_length = 0;
        this.p0 = { x: 0, y: 0 };

        this.node.classList.add(`${this.selector}_loader`);
        this.node.style.width = `${this.options.width}`;
        // this.node.style.height = `${this.options.height}`;
        // this.node.style.cursor = '-webkit-grab';
        // this.node.style.cursor = 'grab';
        // this.node.style.cursor = 'move';
        // this.node.style.cursor = 'move';
        // col-resize
        this.node.style.cursor = 'col-resize';

        if (this.options.type === 'images') {
            this.run();
        } else {
            this.runVideo();
        }

        this.calcPosition = this.calcPosition.bind(this);
        this.calcVideoPosition = this.calcVideoPosition.bind(this);
    }


    isDesktop() {
        return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
    }


    /* Images */
    run() {
        let images = this.options.datas;
        this.appendImages(images);

        // if (this.isDesktop()) {
            this.createEvents(images);
        //  } else {
            this.createTouchEvents(images);
        // }

        setTimeout(() =>{
            this.options.onInit();
        }, 500);
    }

    /* Original */
    /*
    appendImages(datas) {
        let template = '';
        let dataClass = '';
        let src = this.options.lazyLoad ? 'data-src' : 'src';

        datas.forEach((element, index) => {
            dataClass = index === this.activeIndex ? '' : `${this.selector}__hidden`;
            template += `<img class="${this.options.imgClass} ${dataClass}" ${src}="${element}" />`;
        });

        this.node.innerHTML = template;
        this.collection = this.node.querySelectorAll(`.${this.options.imgClass}`);
        this.collection_length = datas.length;
        this.setImages(this.activeIndex);
    }
    */

    /* Blob */
    /*
    appendImages(datas) {
        let self = this;

        function make_image(data) {
            let image = document.createElement('img');
            image.classList.add(`${self.selector}__image`, `${self.selector}__hidden`);
            image.src = window.URL.createObjectURL(data);
            self.node.insertAdjacentElement('beforeend', image);
        }

        let requests = datas.map(url => fetch(url));
        Promise.all(requests).then((response) => {
            let responses = response.map(resp => resp.blob());
            Promise.all(responses).then((results) => {
                results.forEach((item) => {
                    make_image(item);
                });
            }).then(() => {
                this.collection = this.node.querySelectorAll(`.${this.options.imgClass}`);
                this.collection_length = datas.length;
                this.setImages(this.activeIndex);
            })
        });
    }
    */

    // Base64
    appendImages(datas) {
        let self = this;

        // https://github.com/mdn/js-examples/blob/master/promises-test/index.html
        function imgLoad (url) {
            // Create new promise with the Promise() constructor;
            // This has as its argument a function
            // with two parameters, resolve and reject

            return new Promise(function(resolve, reject) {
                // Standard XHR to load an image
                const request = new XMLHttpRequest();
                request.open('GET', url);
                request.responseType = 'blob';
                // request.responseType = 'ArrayBuffer';

                // When the request loads, check whether it was successful
                request.onload = function() {
                    if (request.status === 200) {
                        // If successful, resolve the promise by passing back the request response
                        resolve(request.response);
                    } else {
                        // If it fails, reject the promise with a error message
                        reject(Error('Image didn\'t load successfully; error code:' + request.statusText));
                    }
                };

                request.onerror = function() {
                    // Also deal with the case when the entire request fails to begin with
                    // This is probably a network error, so reject the promise with an appropriate message
                    reject(Error('There was a network error.'));
                };

                // Send the request
                request.send();
            });
        }

        let requests = datas.map(url => imgLoad(url));
        Promise.all(requests).then((response) => {
            let template = '';

            response.forEach((blob) => {
                let imageURL = window.URL.createObjectURL(blob);
                template += `<img class="${self.selector}__image ${self.selector}__hidden" src="${imageURL}" />`;
            })

            self.node.innerHTML = template;
            self.collection = self.node.querySelectorAll(`.${self.options.imgClass}`);
            self.collection_length = datas.length;
            self.setImages(self.activeIndex);
        }).catch(() => {
            let template = '';

            datas.forEach((url) => {
                template += `<img class="${self.selector}__image ${self.selector}__hidden" src="${url}" />`;
            });
            self.node.innerHTML = template;
            self.collection = self.node.querySelectorAll(`.${self.options.imgClass}`);
            self.collection_length = datas.length;
            self.setImages(self.activeIndex);

        });
    }


    setImages(index) {
        this.collection.forEach((element) => {
            element.classList.add(`${this.selector}__hidden`);
        });

        let element = this.collection[index];
        if (this.options.lazyLoad) {
            element.src = index_element.dataset.src;
        }

        element.classList.remove(`${this.selector}__hidden`);
    }


    calcPosition(e) {
        // self.$element.css('cursor', '-webkit-grabbing');
        this.node.style.cursor = 'col-resize';

        let p1 = this.isDesktop() ? { x: e.pageX, y: e.pageY } : { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY };

        if (Math.abs(this.p0.x - p1.x) < Math.abs(this.p0.y - p1.y)) {
            return;
        }

        e.preventDefault();

        // let step_value = 10;
        let step_value = 100;

        if (this.p0.x - p1.x > step_value) {
            if (this.options.reverse)  {
                this.activeIndex++;
                this.activeIndex = this.activeIndex >= this.collection_length ? 0 : this.activeIndex;
            } else {
                this.activeIndex--;
                this.activeIndex = this.activeIndex < 0 ? this.collection_length - 1 : this.activeIndex;
            }

            this.setImages(this.activeIndex);
            this.p0 = p1;

        } else if (this.p0.x - p1.x <= -step_value) {

            if (this.options.reverse)  {
                this.activeIndex--;
                this.activeIndex = this.activeIndex < 0 ? this.collection_length - 1 : this.activeIndex;
            } else {
                this.activeIndex++;
                this.activeIndex = this.activeIndex >= this.collection_length ? 0 : this.activeIndex;
            }

            this.setImages(this.activeIndex);
            this.p0 = p1;
        }
    }


    createTouchEvents() {
        this.listener.addEventListener('touchstart', (e) => {
            // e.preventDefault();
            this.p0 = { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY };
            this.listener.addEventListener('touchmove', this.calcPosition);
        });
    }


    createEvents() {
        this.listener.addEventListener('mousedown', (e) => {
            e.preventDefault();
            this.p0 = { x: e.pageX, y: e.pageY };
            this.listener.addEventListener('mousemove', this.calcPosition);
        });

        this.listener.addEventListener('mouseup', () => {
            // this.listener.style.cursor = 'move';
            this.listener.style.cursor = 'col-resize';
            this.listener.removeEventListener('mousemove', this.calcPosition);
        });

        this.listener.addEventListener('mouseleave', () => {
            // this.listener.style.cursor = 'move';
            this.listener.style.cursor = 'col-resize';
            this.listener.removeEventListener('mousemove', this.calcPosition);
        });

        if (this.options.controls) {
            let self = this;
            this.options.controls.prev.addEventListener('click', () => {
                self.activeIndex--;
                self.activeIndex = self.activeIndex < 0 ? self.collection_length - 1 : self.activeIndex;
                self.setImages(self.activeIndex);
            });

            this.options.controls.next.addEventListener('click', () => {
                self.activeIndex++;
                self.activeIndex = self.activeIndex >= self.collection_length ? 0 : self.activeIndex;
                self.setImages(self.activeIndex);
            });
        }
    }

    /* Video */
    runVideo() {
        // var self = this;
        var datas = this.options.datas;
        self.appendVideo(datas);

        if (this.isDesktop()) {
            this.createVideoEvents(datas);
        } else {
            this.createTouchVideoEvents(datas);
        }
    }


    appendVideo(datas) {
        let template = `<video style="width: 100%; height: auto;" src="${datas[0]}"></video>`;
        this.node.innerHTML = template;
    }


    calcVideoPosition(e) {
        this.node.style.cursor = '-webkit-grabbing'
        let p1 = { x: e.pageX, y: e.pageY };

        if (this.p0.x - p1.x > 20) {
            video.currentTime = video.currentTime + 0.2;
            video.currentTime = video.currentTime === video.duration ? 0 : video.currentTime;
            this.p0 = p1;
        } else if (p0.x - p1.x <= -10) {
            video.currentTime = video.currentTime - 0.2;
            video.currentTime = 0 === video.currentTime ? video.duration : video.currentTime;
            this.p0 = p1;
        }
    }


    createTouchVideoEvents(datas) {
        let video = this.node.querySelector('video') ;
        let duration = video.duration;
        let videoWidth = this.node.offsetWidth;

        this.node.addEventListener('touchstart', (e) => {
            e.preventDefault();
            this.p0 = { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY };
            this.node.addEventListener('touchmove', this.calcVideoPosition);
        });
    }


    createVideoEvents(datas) {
        let video = this.node.querySelector('video') ;
        let duration = video.duration;
        let videoWidth = this.node.offsetWidth;

        this.node.addEventListener('mousedown', (e) => {
            e.preventDefault();
            this.p0 = { x: e.pageX, y: e.pageY };
            this.node.addEventListener('mousemove', this.calcVideoPosition);
        });

        this.node.addEventListener('mouseup', () => {
            this.node.style.cursor = '-webkit-grab';
            this.node.removeEventListener('mousemove', this.calcVideoPosition);
        });

        this.node.addEventListener('mouseleave', () => {
            this.node.style.cursor = '-webkit-grab';
            this.node.removeEventListener('mousemove', this.calcVideoPosition);
        });
    }


    // Destroy
    destroy() {
        this.node.innerHTML = '';
        this.node.removeAttribute('style');
        this.node.classList.remove(`${this.selector}_loader`);
        this.node = null;
        // self.$element.removeAttr('style').removeClass('spin loader');
        // self.$element.replaceWith(self.$element.clone());
    }
}

export { Spin360n };
