
import AOS from 'aos';
import simpleParallax from 'simple-parallax-js';

function animation() {
    const max_limit = 1024;
    let paralax_instance;

    AOS.init({
        once: true,
        disable: window.innerWidth < max_limit || 'mobile',
        disable: 'mobile',
    });

    const elements = document.querySelectorAll('.image-paralax');
    const params = {
        scale: 1.2,
    }

    if (elements.length > 0) {
        paralax_instance = new simpleParallax(elements, params);
    }
}

export { animation };
