import IMask from 'imask';

export default function () {
    const inputs = document.querySelectorAll('input[type="text"], input[type="email"], input[type="tel"]');

    inputs.forEach((node) => {

        if(node.value.length > 0) {
            node.classList.add('form-input_focus');
        }

        node.addEventListener('focus', function() {
            this.classList.remove('form-input_error');
            this.placeholder = '';
        });

        node.addEventListener('blur', function() {
            if (this.value === '') {
                this.placeholder = this.dataset.placeholder;
                this.classList.remove('form-input_focus');
            } else {
                this.classList.add('form-input_focus');
            }
        });

        if (node.type === 'tel') {
            const maskOptions = {
                // mask: '+38\\0 000 00 00',
                mask: '1 (000) 000 00 00',
                lazy: false,
                placeholderChar: ' ',
            };
            IMask(node, maskOptions);
        }
    })
}