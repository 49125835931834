import axios from 'axios';


function page_wait(form) {
    const btn = form.querySelector('input[type="submit"]');
    if (btn) {
        btn.disabled = true;
    }
    document.body.classList.add('wait');
}

function page_unwait(form) {
    const btn = form.querySelector('input[type="submit"]');
    if (btn) {
        btn.disabled = false;
    }
    document.body.classList.remove('wait');
}


function send_data(form) {
    const data = new FormData(form);
    page_wait(form);

    axios.post(form.action, data).then((response) => {
        const rdata = response.data;
        if (rdata.status === 'ok') {
            page_unwait(form);
            popup.view('after_message');
            popup.show();
            form.reset();
        }
    }).catch((error) => {
        page_unwait(form);
        console.error(error);
    });
}


function check_data(form) {
    const inputs = form.querySelectorAll('input[data-required="true"]');
    let result = true;
    let re;
    let local;

    inputs.forEach((input) => {
        if (input.dataset.regexp && input.dataset.regexp !== '') {
            re = new RegExp(input.dataset.regexp, 'i');
            local = re.test(input.value);
        } else {
            local = input.value !== '';
        }

        if (!local) {
            input.classList.add('form-input_error');
        }

        result = result && local;
    });

    return result;
}

function order_form() {
    const form = document.getElementById('online_order');

    if (form) {
        form.addEventListener('submit', (event) => {
            event.preventDefault();
            const self = event.currentTarget;

            if (check_data(self)) {
                send_data(self);
            }
        });
    }
    const calculator_form = document.getElementById('calculator_order');

    if (calculator_form) {
        calculator_form.addEventListener('submit', (event) => {
            event.preventDefault();
            const self = event.currentTarget;

            if (check_data(self)) {
                send_data(self);
            }
        });
    }
    const subscribe_form = document.getElementById('subscribe_form');

    if (subscribe_form) {
        subscribe_form.addEventListener('submit', (event) => {
            event.preventDefault();
            const self = event.currentTarget;
            if (check_data(self)) {
                const data = new FormData(subscribe_form);
                page_wait(subscribe_form);

                axios.post(subscribe_form.action, data).then((response) => {
                    const rdata = response.data;
                    if (rdata.status === 'ok') {
                        subscribe_form.getElementsByTagName('input')[1].value = '';
                        subscribe_form.getElementsByTagName('input')[1].placeholder = subscribe_form.getElementsByTagName('input')[1].dataset.placeholder;
                        page_unwait(subscribe_form);
                        popup.view('after_message_subscribe');
                        popup.show();
                    }
                }).catch((error) => {
                    page_unwait(subscribe_form);
                    console.error(error);
                });
            }
        });
    }


}

export { order_form };