import AOS from 'aos';
import Swiper from 'swiper/bundle';
import throttle from 'lodash/throttle';

export default function() {
    let swiper;
    let wheel_enabled = true;
    const limit_width = 992;
    const fixed_blocks = document.querySelectorAll('.aging-main-scroll-box');

    const animated_elements = document.querySelectorAll('*[data-aos]');

    const params = {
        direction: 'vertical',
        // slidesPerView: 1,
        slidesPerView: 'auto',
        spaceBetween: 0,
        autoHeight: true,
        cssMode: false,
        simulateTouch: false,
        loop: false,

        mousewheel: true,
        passiveListeners: false,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"><span class="slide-label">' + window.slideTitles[index] + '</span></span>';
            },
          },


        // mousewheel: {
        //     sensitivity: 1000,
        // },
        // longSwipes: false,
        // preventInteractionOnTransition: true,
        // passiveListeners: false,


        // mousewheelReleaseOnEdges: true,
        // mousewheelSensitivity: 0.9,

        on: {
            slideChangeTransitionStart: () => {
                swiper.mousewheel.disable();
                wheel_enabled = false;
                animated_elements.forEach((node) => {
                    node.classList.remove('aos-animate');
                });
            },
            slideChangeTransitionEnd: function () {

                if(swiper.slides[swiper.activeIndex].classList.contains('swiper-slide_dark')) {
                    document.body.classList.add('pagination-white');
                } else {
                    document.body.classList.remove('pagination-white');
                }

                let len = swiper.slides.length;
                swiper.slides[swiper.activeIndex].querySelectorAll('*[data-aos]').forEach((node) => {
                    node.classList.add('aos-animate');
                });

                if (swiper.activeIndex === len - 2) {
                    swiper.slides[len - 1].querySelectorAll('*[data-aos]').forEach((node) => {
                        node.classList.add('aos-animate');
                    });
                }

                if (swiper.activeIndex > 0) {
                    document.body.classList.add('gototop-visible');
                } else {
                    document.body.classList.remove('gototop-visible');
                }

                setTimeout(function(){
                    wheel_enabled = true;
                    swiper.mousewheel.enable();

                }, 1000);


            },
        }
    }

    /*$('.card').hide(0);
      $('.card').removeClass('aos-init').removeClass('aos-animate');
    },
    slideChangeTransitionEnd: function () {
      $('.card').show(0);
      AOS.init();
      */

    function set_wheel_event(event) {
        let content = event.currentTarget.querySelector('.container-data');
        if (event.deltaY >= 0 && ( parseInt(content.getBoundingClientRect().bottom) > document.documentElement.clientHeight)) {
            // scroll down
            event.stopPropagation();
        } else if (event.deltaY < 0 && (parseInt(content.getBoundingClientRect().top) < 0) ) {
            // scroll up
            event.stopPropagation();
        }
    }

    function set_slider_event() {
        const window_width = document.documentElement.clientWidth;

        if (window_width >= limit_width && document.body.classList.contains('desktop')) {
            if (!swiper) {
                swiper = new Swiper('.swiper-container', params);
                swiper.on('slideChange', function () {
                    AOS.refreshHard();
                });

            }
            fixed_blocks.forEach((node) => {
                node.addEventListener('wheel', set_wheel_event);
            });
        } else {

            if (swiper) {
                // swiper.destroy(deleteInstance, cleanStyles);
                swiper.destroy(true, true);
                swiper = undefined;
            }
            fixed_blocks.forEach((node) => {
                node.removeEventListener('wheel', set_wheel_event);
            });
        }
    }


    set_slider_event();

    window.addEventListener('resize', throttle(set_slider_event, 100));

    document.querySelectorAll('.aging-main-container').forEach((node) => {
        node.addEventListener('wheel', (event) => {
            if(!wheel_enabled) {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    });

    document.querySelectorAll('.gotolink').forEach((node) => {
        node.addEventListener('click', function(){
            let target_id = parseInt(this.dataset.slideIndex, 10);
            document.body.classList.remove('mobile-nav');
            if (swiper) {
                swiper.slideTo(target_id, Math.abs(swiper.activeIndex + target_id) * 300, function(){});
            } else {
                let target_el = document.querySelector(`*[data-slide-target="${target_id}"]`);
                if (target_el) {
                    window.scrollTo({
                        top: target_el.getBoundingClientRect().y + window.pageYOffset,
                        behavior: 'smooth'
                    });
                }
            }
        });
    });

    let gototop = document.querySelector('.gototop');
    if (gototop) {

        gototop.addEventListener('click', () => {
            try {
                let panorama = document.getElementById('#panorama-mobile');
                panorama.closest('.aging-main-mobile-panorama').classList.add('panorama-locked');
            } catch(e) {
                // empty;
            }

            try {
                swiper.slideTo(0, 300 * swiper.activeIndex);
            } catch(e) {
                // empty
            }

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });

        let screen_height = document.documentElement.clientHeight;
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > (screen_height / 2)) {
                document.body.classList.add('gototop-visible');
            } else {
                document.body.classList.remove('gototop-visible');
            }
        });
    }
}
