export default function () {
  const containerCalculator = document.querySelector(".calculator-form");
  const HEIGHT_ROOM = 8.53;
  const MAX_WEIGHT = 3.075;
  const AREA_FOR_ONE_BEEF = 127;

  function findParentNodeByClass(el, targetParentClass) {
    // Traverse the DOM up with a while loop
    while (!el.classList.contains(targetParentClass)) {
      // Increment the loop to the parent node
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    // At this point, the while loop has stopped and `el` represents the element that has
    // the class you specified in the second parameter of the function `targetParentClass`

    // Then return the matched element
    return el;
  }
  function changeValue(target) {
    let parentTarget = findParentNodeByClass(target, "calculator-form__item");
    const targetValue = target.value;
    const typeTarget = target.getAttribute("type");
    let inputForChange;
    if (typeTarget === "number") {
      inputForChange = parentTarget.querySelector(".numberSlider");
    } else {
      inputForChange = parentTarget.querySelector(".numberQuantity");
    }
    inputForChange.value = targetValue;
  }
  function writeResult(inptNumbers, forResultNode_1, forResultNode_2) {
    let area = inptNumbers[0].value * inptNumbers[1].value;
    let capacity = (area / MAX_WEIGHT) * AREA_FOR_ONE_BEEF;
    forResultNode_1.textContent = area.toFixed(2);
    forResultNode_2.textContent = capacity.toFixed(2);
  }
  if (containerCalculator) {
    const allInputsNumber =
      containerCalculator.querySelectorAll(".numberQuantity");
    const allInputsRange =
      containerCalculator.querySelectorAll(".numberSlider");
    const lessButton = containerCalculator.querySelectorAll(".lessValue");
    const moreButton = containerCalculator.querySelectorAll(".moreValue");
    const areaResult = containerCalculator.querySelector(".areaResult");
    const capacityResult = containerCalculator.querySelector(".capacityResult");
    const hideArea = containerCalculator.querySelector("#hideArea");
    const hideCapacity = containerCalculator.querySelector("#hideCapacity");
    writeResult(allInputsNumber, areaResult, capacityResult);
    hideArea.value = areaResult.textContent;
    hideCapacity.value = capacityResult.textContent;

    for (let index = 0; index < lessButton.length; index++) {
      const element = lessButton[index];
      const parent = findParentNodeByClass(element, "calculator-form__item");
      const inputNumber = parent.getElementsByClassName("numberQuantity")[0];
      const inputSlider = parent.getElementsByClassName("numberSlider")[0];
      element.addEventListener("click", function () {
        const minValue = Number(inputNumber.getAttribute("min"));
        const value = Number(inputNumber.value);
        if (value > minValue) {
          inputNumber.value = (Number(inputNumber.value) - 0.01).toFixed(2);
          inputSlider.value = inputNumber.value;
          writeResult(allInputsNumber, areaResult, capacityResult);
          hideArea.value = areaResult.textContent;
          hideCapacity.value = capacityResult.textContent;
        }
      });
    }
    for (let index = 0; index < moreButton.length; index++) {
      const element = moreButton[index];
      const parent = findParentNodeByClass(element, "calculator-form__item");
      const inputNumber = parent.getElementsByClassName("numberQuantity")[0];
      const inputSlider = parent.getElementsByClassName("numberSlider")[0];
      element.addEventListener("click", function () {
        const maxValue = Number(inputNumber.getAttribute("max"));
        const value = Number(inputNumber.value);
        if (maxValue > value) {
          inputNumber.value = (Number(inputNumber.value) + 0.01).toFixed(2);
          inputSlider.value = inputNumber.value;
          writeResult(allInputsNumber, areaResult, capacityResult);
          hideArea.value = areaResult.textContent;
          hideCapacity.value = capacityResult.textContent;
        }
      });
    }

    for (let index = 0; index < allInputsNumber.length; index++) {
      const element = allInputsNumber[index];
      element.addEventListener("input", function () {
        changeValue(this);
        writeResult(allInputsNumber, areaResult, capacityResult);
        hideArea.value = areaResult.textContent;
        hideCapacity.value = capacityResult.textContent;
      });
      element.addEventListener("change", function () {
        hideArea.value = areaResult.textContent;
        hideCapacity.value = capacityResult.textContent;
      });
    }
    for (let index = 0; index < allInputsRange.length; index++) {
      const element = allInputsRange[index];
      element.addEventListener("input", function () {
        changeValue(this);
        writeResult(allInputsNumber, areaResult, capacityResult);
      });
      element.addEventListener("change", function () {
        hideArea.value = areaResult.textContent;
        hideCapacity.value = capacityResult.textContent;
      });
    }
  }
}
