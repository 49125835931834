export default function() {

    if (!document.querySelector('.popup')) {
        return;
    }

    function PopUp(selector) {
        this.el = document.querySelector(selector);
        this.css = 'popup-active'
    }

    PopUp.prototype.view = function(selector) {
        this.el.className = 'popup ' + selector;
    }

    PopUp.prototype.show = function() {
        const close_height = 0;
        const padding = 10;
        const window_scroll = window.pageYOffset || document.documentElement.scrollTop;
        const window_height = document.documentElement.clientHeight;
        const popup_height = this.el.offsetHeight;
        let top = 0;

        if (window_height < (popup_height + close_height)) {
            top = parseInt(window_scroll + padding + close_height, 10);
        } else {
            top = parseInt(window_scroll +
                window_height / 2 -
                popup_height / 2, 10);
        }

        // this.view('choice-active');
        this.el.style.top = top + 'px';
        document.body.classList.add(this.css);
    }

    PopUp.prototype.hide = function() {
        document.body.classList.remove(this.css);
        let elem = this.el;
        setTimeout(function() {
            elem.className = 'popup';
        }, 500);
    }

    global.popup = new PopUp('.popup');

    const openers = document.querySelectorAll('[data-popup-action="popup-open"]');
    const closers = document.querySelectorAll('[data-popup-action="popup-close"]');

    openers.forEach((node) => {
        node.addEventListener('click', () => popup.show());
    });

    closers.forEach((node) => {
        node.addEventListener('click', () => popup.hide());
    });
}