require('./vendor/libpannellum');
require('./vendor/pannellum');
require('fslightbox');
require('masonry-layout');

import 'simplebar';
// import AOS from 'aos';

import main_slider from './slider';
import { Spin360n as Spin360 } from './spin360-native';
import { animation } from './animation';

import popup from './popup';
import inputs from './inputs';
import { order_form } from './form';
import sandwich from './sandwich';
import calculator from './calculator';

function init_spin() {
    const spin_block = document.getElementById('gallery-360');
    const spin_block_mobile = document.getElementById('gallery-360-mobile');
    // const spin_listener = document.getElementById('spin-pointer');

    if (spin_block && window.GALLERY360) {
        let slider = new Spin360(spin_block, {
            datas: window.GALLERY360,
            lazyLoad: false,
            reverse: false,
            // listener: spin_block,
            reverse: true,
            controls: {
                prev: document.querySelector('.drag-left'),
                next: document.querySelector('.drag-right'),
            }
        });
    }

    const mobile_open = document.querySelector('.mobile-preview-lock');
    if (spin_block_mobile && window.GALLERY360 && mobile_open) {
        let slider_mobile = new Spin360(spin_block_mobile, {
            datas: window.GALLERY360,
            lazyLoad: false,
            reverse: false,
            listener: mobile_open,
            reverse: true,
        });
    }

    if (mobile_open) {
        let p0;
        let parent = mobile_open.closest('.aging-main-mobile-body');
        let locked = true;

        mobile_open.addEventListener('touchstart', (event) => {
            if (locked) {
                p0 = { x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY };
            }
        });

        mobile_open.addEventListener('touchmove', (event) => {
            if (locked) {
                let p1 = { x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY };
                if (Math.abs(p0.x - p1.x) > 9) {
                    parent.classList.remove('gallery-360-locked');
                    locked = false;
                }
            }
        });
    }
}

function init_flag() {
    const spin_block = document.getElementById('gallery-flag');

    if (spin_block && window.GALLERYFLAG) {
        let slider = new Spin360(spin_block, {
            datas: window.GALLERYFLAG,
            lazyLoad: false,
            reverse: false,
            // listener: spin_block,
            reverse: true,
            controls: {
                prev: document.querySelector('.drag-left-flag'),
                next: document.querySelector('.drag-right-flag'),
            }
        });
    }
}

function init_pannellum() {
    const pannelum_box = document.querySelector('#panorama');
    if (pannelum_box) {
        pannellum.viewer('panorama', {
            "type": "equirectangular",
            "panorama": pannelum_box.dataset.src,
            "autoLoad": true,
            "draggable": true,
            "preview": '/panorama/bonlieu.jpg',
            // "previewTitle": 'Click load to panorama',
        });
    }

    const pannelum_box_mobile = document.querySelector('#panorama-mobile');
    if (pannelum_box_mobile) {
        pannellum.viewer('panorama-mobile', {
            "type": "equirectangular",
            "panorama": pannelum_box_mobile.dataset.src,
            "autoLoad": true,
            "draggable": true,
            "preview": '/panorama/bonlieu.jpg',
            // "previewTitle": 'Click load to panorama',
        });
    }

    const btn_open_flag = document.querySelector('.aging-flag');
    if(btn_open_flag) {
        btn_open_flag.addEventListener('click', () => {
            document.body.classList.add('show-flag');
        });
    }

    const btn_close_flag = document.querySelector('.flag-close');
    if(btn_close_flag) {
        btn_close_flag.addEventListener('click', () => {
            document.body.classList.remove('show-flag');
        });
    }

    const btn_open_panarama = document.querySelector('.aging-panorama-btn');
    if(btn_open_panarama) {
        btn_open_panarama.addEventListener('click', () => {
            document.body.classList.add('show-panorama');
        });
    }

    const btn_close_panarama = document.querySelector('.panorama-close');
    if(btn_close_panarama) {
        btn_close_panarama.addEventListener('click', () => {
            document.body.classList.remove('show-panorama');
        });
    }

    // aging-3d-clickable
    const btn_open_3d = document.querySelector('.aging-3d-clickable');
    if(btn_open_3d) {
        btn_open_3d.addEventListener('click', () => {
            document.body.classList.add('show-3d');
        });
    }

    const btn_close_3d = document.querySelector('.aging-close');
    if(btn_close_3d) {
        btn_close_3d.addEventListener('click', () => {
            document.body.classList.remove('show-3d');
        });
    }

    const mobile_open = document.querySelector('.mobile-panorama-lock');
    if (mobile_open) {
        mobile_open.addEventListener('click', () => {
            mobile_open.closest('.aging-main-mobile-body').classList.remove('panorama-locked');
        });
    }

    const mobile_close = document.querySelector('.panorama-mobile-close-container');
    if (mobile_close) {
        mobile_close.addEventListener('click', () => {
            mobile_close.closest('.aging-main-mobile-body').classList.add('panorama-locked');
        });
    }

}

function init() {
    MobileEsp.InitDeviceScan();
    let device = 'desktop';

    if( MobileEsp.DetectTierIphone() ) {
        device = 'phone';
    } else if ( MobileEsp.DetectTierTablet() ) {
        device = 'tablet';
    }

    document.body.classList.add(device);

    main_slider();
    init_spin();
    init_flag();
    init_pannellum();
    // animation();


    popup();
    inputs();
    order_form();
    sandwich();
    calculator();
    // slider();
    // accordion();




}

function load() {
    animation();
    // AOS.refresh();
}

document.addEventListener('DOMContentLoaded', init);
window.addEventListener('load', load);



// import main_slider from './slider';


// function init() {
//     main_slider();
// }


// document.addEventListener('DOMContentLoaded', init);

// import popup from './popup';
// import inputs from './inputs';
// import { order_form } from './form';
// import sandwich from './sandwich';
// import slider from './slider';
// import accordion from './accordion';

// import { animation } from './animation';
// import { get_image } from './utils';

// require('fslightbox');

// (function () {

//     if (typeof(Storage) !== undefined) {
//         global.LS = window.localStorage;
//     }

//     // const image = document.querySelector('.main-visual__image');
//     // if (image) {
//     //     get_image(image);
//     // }

//     function init() {
//         popup();
//         inputs();
//         order_form();
//         sandwich();
//         slider();
//         accordion();

//         // animation();
//     }

//     function load() {
//         animation();
//     }

//     document.addEventListener('DOMContentLoaded', init);
//     // window.addEventListener('load', load);
// }());
