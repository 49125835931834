export default function () {
    const button = document.querySelector('.header-sandwich');
    const body = document.body;
    const CSS_CLASS = 'mobile-nav';

    if (!button) {
        return;
    }

    button.addEventListener('click', () => {
        if (body.classList.contains(CSS_CLASS)) {
            body.classList.remove(CSS_CLASS);
        } else {
            body.classList.add(CSS_CLASS);
        }
    })
}